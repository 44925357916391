"use client";

import * as Sentry from "@sentry/nextjs";
import { useSession } from "next-auth/react";
import { usePostHog } from "posthog-js/react";
import { FC, useEffect } from "react";

const usePosthogUserSession = () => {
  const session = useSession();
  const posthog = usePostHog();

  useEffect(() => {
    if (session.data?.user) {
      posthog.identify(session.data.user.email, { email: session.data.user.email });
    } else {
      posthog.reset();
    }
  }, [posthog, session.data?.user]);
};

const useSentryUserSession = () => {
  const session = useSession();

  useEffect(() => {
    if (session.data?.user) {
      Sentry.setUser({ email: session.data.user.email });
    } else {
      Sentry.setUser(null);
    }
  }, [session.data?.user]);
};

export const CaptureUserSession: FC = () => {
  usePosthogUserSession();
  useSentryUserSession();

  return null;
};
