"use client";

import { FC, PropsWithChildren } from "react";
import { SWRConfig } from "swr";

export const DefaultSWRConfig: FC<PropsWithChildren> = (props) => {
  return (
    <SWRConfig
      {...props}
      value={{
        onError: (error) => {
          console.error(error);
        },
      }}
    />
  );
};
