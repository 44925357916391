"use client";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { FC, PropsWithChildren, useEffect } from "react";

interface Props {
  postHogKey?: string;
  postHogHost?: string;
}

export const PostHogContextProvider: FC<PropsWithChildren<Props>> = ({ children, postHogKey, postHogHost }) => {
  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks
  useEffect(() => {
    if (typeof window !== "undefined" && postHogKey) {
      posthog.init(postHogKey, {
        api_host: postHogHost,
        person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true, // Highly recommended as a minimum!!
            // color: false,
            // date: false,
            // 'datetime-local': false,
            // email: false,
            // month: false,
            // number: false,
            // range: false,
            // search: false,
            // tel: false,
            // text: false,
            // time: false,
            // url: false,
            // week: false,
            // textarea: false,
            // select: false,
          },
        },
      });
    }
  }, []);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};
