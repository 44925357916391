"use client";

import { usePrevious } from "@mantine/hooks";
import { useSession } from "next-auth/react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { FC, ReactNode, createContext, useContext, useEffect } from "react";

type CurrentBranchIdContextValue = [string | undefined | null, (branchId: string) => void];

const CurrentBranchIdContext = createContext<CurrentBranchIdContextValue>([undefined, () => {}]);

const useCurrentBranchId = (): CurrentBranchIdContextValue => {
  const { data: session } = useSession();

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const currentBranchId = searchParams?.get("branch");

  const previousBranchId = usePrevious(currentBranchId);

  const setCurrentBranchId = (branchId: string) => {
    const nextSearchParams = new URLSearchParams(searchParams?.toString());
    nextSearchParams.set("branch", branchId);

    if (branchId) {
      router.replace(`${pathname}?${nextSearchParams.toString()}`, { scroll: false });
    }
  };

  useEffect(() => {
    if (!currentBranchId) {
      const branches = session?.user?.branches;
      setCurrentBranchId(previousBranchId || branches?.[0]?.id!);
    }
  }, [session, currentBranchId, previousBranchId]);

  return [currentBranchId || previousBranchId, setCurrentBranchId];
};

interface CurrentBranchIdContextProviderProps {
  children?: ReactNode;
}

export const CurrentBranchIdContextProvider: FC<CurrentBranchIdContextProviderProps> = ({ children }) => {
  const currentBranchIdState = useCurrentBranchId();

  return <CurrentBranchIdContext.Provider value={currentBranchIdState}>{children}</CurrentBranchIdContext.Provider>;
};

export const useCurrentBranchIdContext = () => useContext(CurrentBranchIdContext);
