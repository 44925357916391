"use client";

import { Branch } from "@prisma/client";
import { FC, ReactNode, createContext, useContext } from "react";

type BranchesContextValue = Record<string, Branch> | undefined;

const BranchesContext = createContext<BranchesContextValue>(undefined);

interface BranchesContextProviderProps {
  children?: ReactNode;
  branches: Record<string, Branch>;
}

export const BranchesContextProvider: FC<BranchesContextProviderProps> = ({ children, branches }) => {
  return <BranchesContext.Provider value={branches}>{children}</BranchesContext.Provider>;
};

export const useBranchesContext = () => useContext(BranchesContext);
